<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(onSubmit)"
      @close="onClose"
      submit="Submit"
      :loading="isLoading"
      :disabled-button="isLoading"
      button-class="bg-dynamicBackBtn text-white"
      width="520px"
    >
      <template v-slot:title>
        <div class="flex justify-start items-center">
          <BackButton @onClick="onClose" variant="secondary" />
          <span class="text-darkPurple font-bold text-lg leading-5 p-0 -ml-2">
            Adjustment Proposal
          </span>
        </div>
      </template>
      <template>
        <div class="border-b border-dashed border-romanSilver mb-5">
          <div class="flex justify-between items-start w-full gap-2">
            <div class="w-4/12 h-60 rounded-md text-blueCrayola font-normal">
                <img class="h-56 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-56 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                </span>
                </div>
              </div>
            <div class="w-8/12 flex flex-col justify-start items-start gap-2">
              <h4 class="font-bold text-lg leading-5 text-darkPurple">
                {{ employeeInfo.fname }} {{ employeeInfo.lname }}
              </h4>
              <div class="flex flex-col justify-start items-center w-full gap-2">
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Function:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.function }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Designation:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.designation }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Job Level:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.level">
                      {{ employeeInfo.level.name }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Office Location:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    {{ employeeInfo.location }}
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Manager:
                  </p>
                  <template v-if="(employeeInfo.lineManager)">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.lineManager.fname }} {{ employeeInfo.lineManager.lname }}
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Length of Service:
                  </p>
                  <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.yearsOfService">
                      {{ employeeInfo.yearsOfService }}
                    </span>
                  </p>
                </div>
                <div class="flex justify-start items-center w-full">
                  <p class="w-3/5 font-semibold text-sm leading-5 text-romanSilver">
                    Last Performance Score:
                  </p>
                  <template v-if="employeeInfo.performanceScore">
                    <p class="w-2/5 font-semibold text-sm leading-5 text-darkPurple">
                      {{ employeeInfo.performanceScore }}
                      <span class="text-red-400" v-if="employeeInfo.performanceScore <= 2">(Poor)</span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 2 && employeeInfo.performanceScore < 4)">
                        (Average)
                      </span>
                      <span class="text-yellow-400" v-if="(employeeInfo.performanceScore > 4)">(Excellent)</span>
                    </p>
                  </template>
                  <p v-else>-</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col justify-start gap-3">
          <div class="w-full">
            <Select
              placeholder="Select"
              class="container w-full"
              :rules="['required']"
              label="Proposal Type"
              :options="adjustmentOptions"
              v-model="payload.proposalType"
            />
          </div>
          <div class="w-full">
            <TextInput
              placeholder="Recommendation"
              class="w-full"
              :rules="['required']"
              label="Recommendation"
              v-model="payload.adjustTo"
            />
          </div>
          <validation-provider class="w-full" name="Justification" :rules="{required: true}" v-slot="{ errors }">
            <div class="flex flex-col justify-start items-start w-full gap-1">
              <span class="font-normal text-sm text-darkPurple">Justification</span>
              <div class="w-full flex flex-col justify-start gap-2">
                <Textarea
                  class="w-full"
                  label="Justification"
                  placeholder="Text Area"
                  :height="144" :maxlength="3"
                  v-model="payload.justification"
                />
                <div class="w-full flex flex-row justify-between text-sm">
                  <p v-if="payload.justification.length > 300" class="text-flame w-full whitespace-nowrap">
                    * Justification should not exceed max count of 300
                  </p>
                  <p class="font-normal text-xs leading-5 text-jet flex justify-end w-full">
                    {{ payload.justification.length }}/300
                  </p>
                </div>
              </div>
            </div>
            <span class="text-flame">{{ errors[0] }}</span>
          </validation-provider>
          <template>
            <div class="flex flex-col justify-start items-start w-full gap-2 -mt-2">
                <span class="text-base font-semibold leading-5 text-darkPurple">
                  Attach Letter
                </span>
                <div class="w-full flex items-center justify-center border border-dashed p-3 border-romanSilver rounded-md">
                <div @click="isOpenAttachment = !isOpenAttachment"
                  class="bg-ghostWhite p-5 rounded-md shadow cursor-pointer">
                  <Icon @click="isOpenAttachment = !isOpenAttachment" icon-name="icon-plus-grey" size="s" />
                </div>
              </div>
            </div>
            <template v-if="isOpenAttachment">
              <div class="overlayer flex flex-wrap justify-center items-center">
                <Card class="bg-white relative p-4 flex flex-col justify-end items-end gap-2">
                  <div @click="isOpenAttachment = !isOpenAttachment">
                    <Icon icon-name="close" size="xs" class="text-darkPurple cursor-pointer" />
                  </div>
                  <file-upload />
                </Card>
              </div>
            </template>
          </template>
        </div>
      </template>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import FileUpload from '@scelloo/cloudenly-ui/src/components/file-upload';
  import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
  import Textarea from "@scelloo/cloudenly-ui/src/components/text-area";
  import TextInput from "@scelloo/cloudenly-ui/src/components/text";
  import Select from "@scelloo/cloudenly-ui/src/components/select";
  import Card from "@scelloo/cloudenly-ui/src/components/card";
  import RightSideBar from "@/components/RightSideBar";
  import Icon from "@/components/Icon";

  export default {
    components: {
      FileUpload,
      ValidationObserver,
      ValidationProvider,
      BackButton,
      Textarea,
      TextInput,
      Select,
      Card,
      RightSideBar,
      Icon
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        isOpenAttachment: false,
        employeeInfo: {},
        payload: {
          proposalType: '',
          adjustTo: '',
          justification: '',
        },
        adjustmentOptions: [
          { name: 'Discretionary Adjustment', id: 'dicretionary_adjustment'},
          { name: 'Promote Employee', id: 'promote'},
          { name: 'Award Lump Sum', id: 'award_lump_sum'}
        ],
      }
    },
    methods: {
      async toggle({ userId, paygradeId }){
        this.getEmployeeCompensationSummary({ userId, paygradeId })
        this.userId = userId
        this.isOpen = true
      },
      onClose(){
        this.isOpen = false
        this.isLoading = false
        Object.assign(this.$data.payload, this.$options.data().payload)
      },
      getEmployeeCompensationSummary(payload){
        this.$_getEmployeeCompensationSummary(payload).then(({ data }) => {
          this.employeeInfo = {
            ...data.employee,
            ...data.employee.paygrade,
            ...data.employee.userDetails,
          }
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 })
          this.isLoading = false
        })
      },
      onSubmit(){
        this.isLoading = true

        const payload = {
          ...this.payload,
          orgId: this.$orgId,
          userId: this.userId,
          reviewId: this.$route.params.id
        }

        this.$_postOrgAdjustmentProposals(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.$emit('submit', data.success)
          this.onClose()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
    }
  }
</script>

<style>
  .rightSidebar{
    width: 540px !important;
  }
</style>

